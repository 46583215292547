<template>
  <div class="bg-light">
    <h6>{{ $t("RUNNING_TIPS") }}</h6>
    <hr />
    <table class="mt-4">
      <tr>
        <th class="text-center">{{ $t("DATE") }}</th>
        <th class="text-center">{{ $t("EVENT") }}</th>
        <th class="text-center">{{ $t("BETTING_TYPE") }}</th>
        <th class="text-center">{{ $t("RESULT") }}</th>
        <th class="text-center">{{ $t("WAGER") }}</th>
        <th class="text-center">{{ $t("ODDS") }}</th>
        <th class="text-center">+/-</th>
        <th class="text-center">{{ $t("TIP") }}</th>
      </tr>
      <tr>
        <td class="text-center">
          <div class="m-0 p-0" style="color: #1c50ed">05.10.23</div>
          14:00
        </td>
        <td class="text-center">
          <a class="m-0 p-0" style="color: #1c50ed" href="/fixture-tips/000001">
            Hougang United FC - Hai Phong
          </a>
          <br />
          AFC Cup-AFC Cup Grp. H
        </td>
        <td class="text-center">
          Asian Handicap<br />
          Full Time
        </td>
        <td class="text-center">
          {{ timerOutput }}<br />
          Until Kick-Off
        </td>
        <td class="text-center" colspan="4">
          <button type="button" class="btn btn-primary btn-block btn-sm">
            $350.00
          </button>
        </td>
        <!-- <td class="text-center"></td>
        <td class="text-center"></td>
        <td class="text-center"></td> -->
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {},
  components: {},
  mounted() {
    setInterval(() => {
      this.startTimer();
    }, 1000);
  },
  data() {
    return {
      show: true,
      countDownToTime: new Date("Sep 26, 2029 00:00:00").getTime(),
      timerOutput: null,
    };
  },
  methods: {
    sendName(val) {
      return `${val.toLowerCase().split(" ")[0]}-${
        val.toLowerCase().split(" ")[1]
      }`;
    },
    startTimer() {
      const timeNow = new Date().getTime();
      const timeDifference = this.countDownToTime - timeNow;
      const millisecondsInOneSecond = 1000;
      const millisecondsInOneMinute = millisecondsInOneSecond * 60;
      const millisecondsInOneHour = millisecondsInOneMinute * 60;
      const millisecondsInOneDay = millisecondsInOneHour * 24;
      const differenceInDays = timeDifference / millisecondsInOneDay;
      const remainderDifferenceInHours =
        (timeDifference % millisecondsInOneDay) / millisecondsInOneHour;
      const remainderDifferenceInMinutes =
        (timeDifference % millisecondsInOneHour) / millisecondsInOneMinute;
      const remainderDifferenceInSeconds =
        (timeDifference % millisecondsInOneMinute) / millisecondsInOneSecond;
      const remainingDays = Math.floor(differenceInDays);
      const remainingHours = Math.floor(remainderDifferenceInHours);
      const remainingMinutes = Math.floor(remainderDifferenceInMinutes);
      const remainingSeconds = Math.floor(remainderDifferenceInSeconds);
      this.timerOutput =
        remainingHours +
        "H:" +
        remainingMinutes +
        "M:" +
        remainingSeconds +
        "S";
    },
  },
};
</script>

<style lang="scss">
.status {
  background-color: rgb(0, 0, 0);
  border-radius: 4px;
  margin: 2px 0px 0px 0px;
  padding: 0px 6px 3px 6px !important;
  span {
    font-size: 12px;
    color: rgb(255, 255, 255);
  }
}
p {
  font-size: 12px;
}
a {
  font-size: 12px;
}
hr {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border: 0 !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}
table {
  font-family: "Kanit", sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 12px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
/* we will explain what these classes do next! */
</style>
