<template>
  <div class="bg-light">
    <table>
      <tr>
        <th class="text-center">{{ $t("DATE") }}</th>
        <th class="text-center">{{ $t("EVENT") }}</th>
        <th class="text-center">{{ $t("BETTING_TYPE") }}</th>
        <th class="text-center">{{ $t("TIP") }}</th>
        <th class="text-center">{{ $t("ODDS") }}</th>
        <th class="text-center">{{ $t("WAGER") }}</th>
        <th class="text-center">{{ $t("PLUS") }}</th>
        <th class="text-center">{{ $t("RESULT") }}</th>
      </tr>
      <tr>
        <td class="text-center">
          <div class="m-0 p-0" style="color: #1c50ed">05.10.23</div>
          14:00
        </td>
        <td class="text-center">
          <div class="m-0 p-0" style="color: #1c50ed">
            Hougang United FC - Hai Phong
          </div>
          AFC Cup-AFC Cup Grp. H
        </td>
        <td class="text-center">
          Asian Handicap<br />
          Full Time
        </td>
        <td class="text-center">Hougang United FC +1</td>
        <td class="text-center">1.8</td>
        <td class="text-center">10</td>
        <td class="text-center">+8</td>
        <td class="text-center">
          <div class="m-0 p-0" style="color: green">WIN</div>
          2-1 (0-0)
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    tipsters: {
      type: Object,
    },
  },
  components: {},
  mounted() {},
  data() {
    return { show: true };
  },
  methods: {
    sendName(val) {
      return `${val.toLowerCase().split(" ")[0]}-${
        val.toLowerCase().split(" ")[1]
      }`;
    },
  },
};
</script>

<style lang="scss">
.status {
  background-color: rgb(0, 0, 0);
  border-radius: 4px;
  margin: 2px 0px 0px 0px;
  padding: 0px 6px 3px 6px !important;
  span {
    font-size: 12px;
    color: rgb(255, 255, 255);
  }
}
p {
  font-size: 12px;
}
a {
  font-size: 14px;
}
hr {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border: 0 !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}
table {
  font-family: "Kanit", sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 12px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
/* we will explain what these classes do next! */
</style>
